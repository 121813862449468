import { history } from "components/common/BrowserRouter";
import { last } from "lodash";
import qs from "qs";

// prettier-ignore
export class Path extends String {
  newTab = () => {
    window.open(this.toString(), "__blank");
  };

  push = (extendedPath: String = "") => {
    if (history) {
      history.push(`${this.toString()}${extendedPath ?? ""}`);
    }
  };

  reload = () => {
    window.location.href = this.toString();
  };

  makePath = (path: Function): Function => {
    return (...args: any[]) => {
      let pathMethod = typeof path === "string" ? () => path : path;
      let absolutePath = null;

      if (last(args) instanceof Object) {
        const params = args.slice(0, args.length - 1);

        const query = last(args);
        const queryString = qs.stringify(query);

        absolutePath = `${this}/${pathMethod(...params)}?${queryString}`;
      } else {
        absolutePath = `${this}/${pathMethod(...args)}`;
      }

      return new Path(absolutePath.replace(/\/+/, "/"));
    };
  };

  page404Path = this.makePath(() => `404`);
  page500Path = this.makePath(() => `500`);

  signInPath = this.makePath(() => `auth/sign-in`);
  signUpPath = this.makePath(() => `auth/sign-up`);
  setupPasswordPath = this.makePath(() => `auth/setup-password`);
  resetPasswordPath = this.makePath(() => `auth/reset-password`);

  adminsPath = this.makePath(() => `admins`);
  adminNewPath = this.makePath(() => `admins/new`);
  adminEditPath = this.makePath((id: any) => `admins/${id}/edit`);

  rolesPath = this.makePath(() => `roles`);
  roleNewPath = this.makePath(() => `roles/new`);
  roleEditPath = this.makePath((id: any) => `roles/${id}/edit`);

  insurancePackageNewPath = this.makePath(
    (insuranceId: any) => `insurance/${insuranceId}/packages/new`
  );
  insurancePackageEditPath = this.makePath(
    (insuranceId: any, id: any) =>
      `insurance/${insuranceId}/packages/${id}/edit`
  );
  insurancePackageDuplicatePath = this.makePath(
    (insuranceId: any, duplicateId: any) =>
      `insurance/${insuranceId}/packages/duplicate/${duplicateId}`
  );
  insurancePackageViewInsurancePath = this.makePath(
    (insuranceId: any, id: any) =>
      `insurance/${insuranceId}/packages/insurance/${id}`
  );

  insurancePath = this.makePath(() => `insurance`);
  insuranceDetailPath = this.makePath((id: any) => `insurance/${id}`);
  addInsurancePath = this.makePath(
    (id: any) => `insurance/${id}/insurance_creating/new`
  );
  editInsurancePath = this.makePath(
    (id: any, insuranceId: any) =>
      `insurance/${id}/insurance_creating/edit/${insuranceId}`
  );
  duplicateInsurancePath = this.makePath(
    (id: any, duplicateId: any) =>
      `insurance/${id}/insurance_creating/duplicate/${duplicateId}`
  );

  enrollmentPath = this.makePath(() => `enrollment`);
  enrollmentDetailPath = this.makePath((id: any) => `enrollment/${id}`);
  enrollmentInsurancePath = this.makePath(
    (id: any, insuranceId: any) =>
      `enrollment/${id}/insurance_plans/${insuranceId}`
  );

  reportPath = this.makePath(() => `report`);
  marketplaceReportPath = this.makePath(() => `marketplace_report`);

  settingsPath = this.makePath(() => `settings`);

  homePath = this.adminsPath;
}

export default new Path();
