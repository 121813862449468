import {
  Alert,
  Box,
  Button,
  CKEditor,
  Field,
  FieldArray,
  Form,
  Grid,
  Modal,
  TextField,
  Typography,
} from "components";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import { PageContent } from "layouts";
import { Yup, gql, homePath, notifySuccess, paths } from "utils/helper";

import { history } from "components/common/BrowserRouter";
import Select from "components/common/Select";
import { EnumInsuranceFor } from "constants/enums/insurance-for";
import { PackagePricingType } from "constants/enums/package-pricing-type";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { isEmpty } from "lodash";
import insuranceStore from "stores/insuranceStore";
import { AppColor } from "theme/app-color";
import { insuranceStepPath } from "../insuranceRoutes";
import { InsuranceSelector } from "./InsuranceSelector";

export const InsurancePackagesNewPage = (props: any) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <Box>
      <Typography variant="h4" mb={6}>
        {props.t(".newPackageHeader")}
      </Typography>
      <Form>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              name="nameTh"
              label={props.t(".packageNameTh")}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              name="nameEn"
              label={props.t(".packageNameEn")}
              required
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <Field
              component={Select}
              name="isFamily"
              type="text"
              label={props.t(".insuranceFor")}
              options={props.insuranceForOptions}
              fast
              required
              fullWidth
              disableClearable={true}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <Field
              component={Select}
              label={props.t(".priceType")}
              name="packagePricingType"
              options={props.packagePricingOptions}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              label={`${props.t(".premium")}`}
              fullWidth
              component={TextField}
              name="premium"
              placeholder=""
              type="number"
              thousandSeparator
              disabled={props.viewOnly || props.disabledPricingType}
              required={props.requiredPricingType}
              fast={false}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <Field
              type="number"
              label={props.t(".coverageLevel")}
              fullWidth
              component={TextField}
              name="coverageLevel"
              placeholder=""
              disabled={props.viewOnly}
              required
            />
            <Box mt="6px">
              <Typography variant="caption" color={AppColor["Text/Line"]}>
                {props.t(".coverageLevelHelper")}
              </Typography>
            </Box>
          </Grid> */}
          <Grid item xs={12}>
            <Alert severity="info">
              <Typography variant="body1" color={AppColor["Text/Primary"]}>
                {props.t(".requiredPackage")}
              </Typography>
            </Alert>
          </Grid>
          {props.insuranceType && props.insuranceType.length > 0 && (
            <Grid item xs={12}>
              <FieldArray
                name="insurances"
                component={InsuranceSelector}
                insuranceType={props.insuranceType}
                viewState={props.viewInsuranceState}
                packageIndex={props.packageIndex}
              />
            </Grid>
          )}
        </Grid>
        <Field
          component={TextField}
          name="remarkTh"
          label={props.t(".remarkTh")}
          fullWidth
        />
        <Box mt={6}>
          <Field
            component={TextField}
            name="remarkEn"
            label={props.t(".remarkEn")}
            fullWidth
          />
        </Box>
        {/* <Box mt={6}>
          <Field
            fullWidth
            component={CKEditor}
            name="description"
            placeholder=""
            disabled={props.viewOnly}
          />
        </Box> */}
        <Box display="flex" mt={10}>
          <Button
            width={74}
            p={0}
            mr={2}
            onClick={props.onBack}
            variant="outlined"
          >
            {props.t(".cancel")}
          </Button>
          <Button type="submit" width={74} p={0} variant="contained">
            {props.t(".save")}
          </Button>
        </Box>
      </Form>
    </Box>
  </PageContent>
);

const API = {
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
  FETCH_INSURANCE_DETAIL: gql`
    query FETCH_INSURANCE_DETAIL($id: String!) {
      masterInsuranceDetail(id: $id) {
        id
        year
        state
        companyName
        protectionStartDate
        protectionEndDate
        insurances {
          id
          nameTh
          nameEn
          premium
          remarkTh
          remarkEn
          description
          insuranceTypeId
          isFamily
          coverageLevel
        }
        insurancePackages {
          id
          nameTh
          nameEn
          remarkTh
          remarkEn
          insurancesInsurancePackages {
            id
            insurancePlanId
            packageId
          }
          isFamily
          coverageLevel
          description
          premium
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    packages: stores.insuranceStore.packages,
  })),
  withFormik({
    mapPropsToValues: (props: any) => {
      const { insuranceId } = props.match.params;
      if (isEmpty(props.packages)) {
        insuranceStepPath.insurancePackage(insuranceId).push();
      }
      return {
        nameTh: "",
        nameEn: "",
        packagePricingType: "",
        insurances: ["", "", ""],
        remarkTh: "",
        remarkEn: "",
        isFamily: null,
        coverageLevel: null,
        description: "",
        premium: null,
      };
    },
    validationSchema: Yup.object().shape({
      nameTh: Yup.string()
        .nullable()
        .test(
          "is-not-duplicate-name-th",
          ".packageNameThDuplicate",
          (value, context) => {
            const packages = insuranceStore.state.packages;
            const editId = context?.parent?.editId;
            const filteredPackages = editId
              ? Object.values(packages).filter(
                  (item: any) => item.id !== editId
                )
              : Object.values(packages);
            return !filteredPackages.some(
              (filteredPackage: any) => filteredPackage.nameTh === value
            );
          }
        ),
      nameEn: Yup.string()
        .nullable()
        .test(
          "is-not-duplicate-name-en",
          ".packageNameEnDuplicate",
          (value, context) => {
            const packages = insuranceStore.state.packages;
            const editId = context?.parent?.editId;
            const filteredPackages = editId
              ? Object.values(packages).filter(
                  (item: any) => item.id !== editId
                )
              : Object.values(packages);
            return !filteredPackages.some(
              (filteredPackage: any) => filteredPackage.nameEn === value
            );
          }
        ),
      packagePricingType: Yup.string().required(".required").nullable(true),
      // coverageLevel: Yup.number().min(1, ".GteOne").typeError(".required"),
      premium: Yup.number().nullable().min(0, ".GteZero"),
      insurances: Yup.array().of(
        Yup.string()
          .nullable()
          .test(
            "at-least-one-not-empty",
            ".notEmptyAtLeastOne",
            (value, context) => {
              const insurances = context?.parent;
              return insurances.some((insurance: any) => !isEmpty(insurance));
            }
          )
      ),
    }),
  }),
  withTranslation({ prefix: "pages.main.insurance.InsurancePackage" }),
  withPreventLeaveDirtyForm({ onOk: () => history.goBack() }),
  withHooks((props: any, hooks: any) => {
    const {
      useHandleSubmit,
      useParams,
      useQuery,
      useMemo,
      useCallback,
      useEffect,
      useState,
    } = hooks;

    const { t, values, setFieldValue } = props;

    const [disabledPricingType, setDisabledPricingType] = useState(false);

    const { insuranceId } = useParams();
    const packagePricingOptions = useMemo(() => {
      const options = Object.keys(PackagePricingType).map((key) => {
        const packagePricingType =
          PackagePricingType[key as keyof typeof PackagePricingType];
        const label = t(`.${packagePricingType}`);
        const value = key;
        return { label, value };
      });
      return options;
    }, [t]);

    const { data: types, loading: insuranceTypeLoading } = useQuery(
      API.FETCH_INSURANCE_TYPES
    );
    const { data: detail, loading: detailLoading } = useQuery(
      API.FETCH_INSURANCE_DETAIL,
      {
        variables: { id: insuranceId },
        onCompleted: (data: any) => {
          const response = data.masterInsuranceDetail;
          insuranceStore.initialMasterInsurance(response);
        },
        fetchPolicy: "network-only",
      }
    );

    const insuranceType = useMemo(() => types?.insuranceTypes || [], [
      types?.insuranceTypes,
    ]);

    const title = t(".add");

    useHandleSubmit(
      async (values: any) => {
        const clearEmptyInsurances = values.insurances.filter(
          (value: any) => !isEmpty(value)
        );

        if (clearEmptyInsurances.length > 0) {
          insuranceStore.saveInsurancePackageTemp({
            ...values,
            isFamily: false,
            coverageLevel: 1,
          });
          onBack();
          notifySuccess(t(".success"));
        } else {
          Modal.alert({
            title: "ไม่สามารถบันทึกได้",
            children: "กรุณาเลือกแบบประกันอย่างน้อย 1 ประเภท",
          });
        }
      },
      [insuranceId]
    );
    const onBack = useCallback(
      () => insuranceStepPath.insurancePackage(insuranceId).push(),
      [insuranceId]
    );

    const isLoading = useMemo(() => {
      return detailLoading || insuranceTypeLoading;
    }, [detailLoading, insuranceTypeLoading]);

    const config = useMemo(() => {
      if (isLoading) return;
      const year = detail?.masterInsuranceDetail.year;
      return {
        year,
      };
    }, [isLoading, detail]);

    const breadcrumbs = useMemo(() => {
      return [
        {
          path: homePath(),
          label: t(".breadcrumbs.home"),
        },
        {
          path: paths.insurancePath(),
          label: t(".breadcrumbs.insurance"),
        },
        {
          path: insuranceStepPath.insurancePackage(insuranceId).toString(),
          label: t(".breadcrumbs.insuranceDetail", {
            year: config?.year,
          }),
        },
        { path: null, label: title },
      ];
    }, [t, insuranceId, config, title]);

    const insuranceForOptions = useMemo(() => {
      return Object.keys(EnumInsuranceFor).map((key) => ({
        label: t(`.insuranceForOptions.${key}`),
        value: Boolean(EnumInsuranceFor[key as keyof typeof EnumInsuranceFor]),
      }));
    }, [t]);

    const isFamily = useMemo(() => values?.isFamily, [values?.isFamily]);

    const requiredPricingType = useMemo(
      () => values?.packagePricingType !== PackagePricingType.ALL_REMAINING,
      [values?.packagePricingType]
    );

    const setPremium = useCallback(() => {
      const insurances = values?.insurances;
      let sum = 0;
      insurances.forEach((id: string) => {
        const findInsurance = detail?.masterInsuranceDetail?.insurances.find(
          (insurance: any) => insurance.id === id
        );
        sum += Number(findInsurance?.premium || 0);
      });
      setFieldValue("premium", sum);
    }, [
      detail?.masterInsuranceDetail?.insurances,
      setFieldValue,
      values?.insurances,
    ]);

    useEffect(() => {
      if (values?.packagePricingType === PackagePricingType.SUM_INSURANCES) {
        setPremium();
      }
    }, [setPremium, values?.packagePricingType]);

    useEffect(() => {
      switch (values?.packagePricingType) {
        case PackagePricingType.SUM_INSURANCES:
          setDisabledPricingType(true);
          break;
        case PackagePricingType.ALL_REMAINING:
          setDisabledPricingType(true);
          setFieldValue("premium", null);
          break;
        // case PackagePricingType.SET_OWN_PRICE:
        //   setDisabledPricingType(false);
        //   setFieldValue("premium", null);
        //   break;
        default:
          setDisabledPricingType(false);
      }
    }, [setFieldValue, values?.packagePricingType]);

    useEffect(() => {
      setFieldValue("insurances", ["", "", ""]);
    }, [setFieldValue, values.isFamily]);

    return {
      onBack,
      title,
      breadcrumbs,
      viewInsuranceState: "new",
      t,
      insuranceType,
      packagePricingOptions,
      insuranceForOptions,
      isFamily,
      disabledPricingType,
      requiredPricingType,
    };
  })
);

export default enhancer(InsurancePackagesNewPage);
