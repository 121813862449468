import { Box, Button, Grid, Modal, Paper, Typography } from "components";
import Loader from "components/Loader";
import { EnumEnrollmentSubState } from "constants/enums/enrollment-sub-state";

import Authorize from "components/Authorize";
import { StepConfigs, Stepper } from "components/common/Stepper";
import { EnumEnrollmentState } from "constants/enums/enrollment-state";
import { PERMISSIONS } from "constants/enums/permissions";
import { compose, withHooks, withStores, withTranslation } from "enhancers";
import { TFunction } from "i18next";
import PageContent, { BreadcrumbsProps } from "layouts/PageContent";
import { get, isEqual } from "lodash";
import enrollmentStore from "stores/enrollmentStore";
import insuranceStore from "stores/insuranceStore";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { gql, homePath, paths, publishedAlert } from "utils/helper";
import {
  EnrollmentRoutes,
  handleNavigateEnrollmentStep,
} from "./enrollmentRoutes";

const PageSectionContainer = styled(Grid)`
  margin-top: 24px;
  display: flex;
`;

const PendingButton = styled(Button)({});

interface EnrollmentDetailComponentProps {
  t: TFunction;
  title: string;
  breadcrumbs: BreadcrumbsProps;
  loading: boolean;
  stateEnrollment: string;
  isPending: boolean;
  displayPendingButton: boolean;
  step: number;
  stepConfigs: StepConfigs[];
  viewOnly: boolean;
  maxStep: number;
  isPublished: boolean;
  handleChangeMaxState: (state: EnumEnrollmentSubState) => void;
  handlePendingEnrollment: () => void;
}

const EnrollmentDetailComponent = (props: EnrollmentDetailComponentProps) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    paper={false}
  >
    {props.loading ? (
      <Loader />
    ) : (
      <PageSectionContainer container>
        <Grid item xs={3}>
          <Paper px={4} py={6} mr={6}>
            <Typography variant="h4">
              {props.t(".step", { step: props.step + 1, of: 6 })}
            </Typography>
            {props.stateEnrollment != null && (
              <div style={{ marginTop: "10px" }}>
                <Typography
                  variant="body1"
                  color={
                    props.isPublished
                      ? AppColor["Other/Success"]
                      : AppColor["Text/Dark Grey"]
                  }
                >
                  {props.stateEnrollment}
                </Typography>
              </div>
            )}
            <Stepper
              step={props.step}
              stepConfigs={props.stepConfigs}
              maxStep={props.maxStep}
            />
          </Paper>
          <Authorize permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}>
            {props.displayPendingButton && (
              <Paper px={4} py={4} mr={6} mt={4}>
                <Typography variant="caption" color={AppColor["Text/Line"]}>
                  {props.t(".pendingText")}
                </Typography>
                <Button
                  mt={4}
                  fullWidth
                  onClick={props.handlePendingEnrollment}
                  disabled={props.isPending}
                  variant="outlined"
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    backgroundColor: props.isPending
                      ? AppColor["Background/Background"]
                      : "inherit",
                    color: props.isPending
                      ? AppColor["Text/Line"]
                      : AppColor["Other/Danger"],
                    border: props.isPending
                      ? `1px solid ${AppColor["Text/Line"]}`
                      : `1px solid ${AppColor["Other/Danger"]}`,
                  }}
                >
                  {props.t(".pendingButton")}
                </Button>
              </Paper>
            )}
          </Authorize>
        </Grid>

        <Grid item xs={9}>
          <Box>
            <EnrollmentRoutes />
          </Box>
        </Grid>
      </PageSectionContainer>
    )}
  </PageContent>
);

const API = {
  GET_ENROLLMENT: gql`
    query GET_ENROLLMENT($id: String!) {
      optimizedEnrollment(id: $id) {
        id
        year
        state
        subState
        updatedAt
        amount
        startDate
        endDate
        existingAttendeeSelectionPeriodInDays
        incomingAttendeeSelectionPeriodInDays
        masterInsurance {
          id
          insurances {
            id
            nameTh
            nameEn
            premium
            remarkTh
            remarkEn
            description
            insuranceTypeId
          }
          insurancePackages {
            id
            nameTh
            nameEn
            remarkTh
            remarkEn
            insurancesInsurancePackages {
              id
              insurancePlanId
              packageId
            }
          }
        }
      }
    }
  `,
  RE_EDIT_MASTER_INSURANCE: gql`
    mutation RE_EDIT_MASTER_INSURANCE($id: String!) {
      reEditMasterInsurance(id: $id) {
        id
        year
        state
      }
    }
  `,
};

const STEP = {
  [EnumEnrollmentSubState.period_editing]: 0,
  [EnumEnrollmentSubState.attendees_validating]: 1,
  [EnumEnrollmentSubState.insurance_plans_validating]: 2,
  [EnumEnrollmentSubState.insurance_packages_validating]: 3,
  [EnumEnrollmentSubState.attendee_groups_editing]: 4,
  [EnumEnrollmentSubState.validating]: 5,
  [EnumEnrollmentSubState.completed]: 5,
};

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({ prefix: "pages.main.enrollment.detail" }),
  withHooks((props: any, hooks: any) => {
    const { t } = props;
    const {
      useParams,
      useMemo,
      useState,
      useCallback,
      useEffect,
      useQuery,
      useMutation,
      useLocation,
    } = hooks;
    const { id } = useParams();
    const { pathname } = useLocation();

    const currentState: EnumEnrollmentSubState = useMemo(() => {
      const state = pathname.split("/")[3] as keyof typeof STEP;
      return state;
    }, [pathname]);

    const { data: enrollmentResponse, loading, refetch } = useQuery(
      API.GET_ENROLLMENT,
      {
        variables: { id },
        fetchPolicy: "network-only",
        onCompleted: (data: { optimizedEnrollment: any }) => {
          const {
            year,
            startDate,
            endDate,
            existingAttendeeSelectionPeriodInDays,
            incomingAttendeeSelectionPeriodInDays,
            masterInsurance,
          } = data.optimizedEnrollment;

          enrollmentStore.setState({
            year,
            startDate,
            endDate,
            existingAttendeeSelectionPeriodInDays,
            incomingAttendeeSelectionPeriodInDays,
            masterInsuranceId: masterInsurance.id,
          });

          insuranceStore.initialMasterInsurance(masterInsurance);
        },
      }
    );

    const [reEditMasterInsurance] = useMutation(API.RE_EDIT_MASTER_INSURANCE, {
      skipSetError: true,
      onCompleted: async () => {
        await refetch();
      },
      onError: (errorResponse: any) => {
        const errors = get(errorResponse, "networkError.result.errors");
        if (errors[0].message === "updateUnsuccessfully") {
          publishedAlert();
        }
      },
    });

    const enrollment = useMemo(() => enrollmentResponse?.optimizedEnrollment, [
      enrollmentResponse,
    ]);

    const [step, setStep] = useState();
    const [maxStep, setMaxStep] = useState();

    const title = useMemo(() => {
      return t(".title", { year: enrollment?.year });
    }, [t, enrollment]);

    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        {
          path: homePath(),
          label: t(".home"),
        },
        {
          path: paths.enrollmentPath(),
          label: t(".enrollment"),
        },
        {
          path: null,
          label: title,
        },
      ],
      [t, title]
    );

    const stepConfigs = useMemo(
      () => [
        {
          onClick: () =>
            handleNavigateEnrollmentStep(
              id,
              EnumEnrollmentSubState.period_editing
            ),
          label: t(".state.1"),
        },
        {
          onClick: () =>
            handleNavigateEnrollmentStep(
              id,
              EnumEnrollmentSubState.attendees_validating
            ),
          label: t(".state.2"),
        },
        {
          onClick: () =>
            handleNavigateEnrollmentStep(
              id,
              EnumEnrollmentSubState.insurance_plans_validating
            ),
          label: t(".state.3"),
        },
        {
          onClick: () =>
            handleNavigateEnrollmentStep(
              id,
              EnumEnrollmentSubState.insurance_packages_validating
            ),
          label: t(".state.4"),
        },
        {
          onClick: () =>
            handleNavigateEnrollmentStep(
              id,
              EnumEnrollmentSubState.attendee_groups_editing
            ),
          label: t(".state.5"),
        },
        {
          onClick: () =>
            handleNavigateEnrollmentStep(id, EnumEnrollmentSubState.validating),
          label: t(".state.6"),
        },
      ],
      [t, id]
    );

    const viewOnly = useMemo(() => {
      let valid = true;
      valid =
        valid && isEqual(enrollment?.state, EnumEnrollmentState.published);
      valid = valid || isEqual(enrollment?.state, EnumEnrollmentState.active);
      valid = valid || isEqual(enrollment?.state, EnumEnrollmentState.closed);
      return valid;
    }, [enrollment]);
    const stateEnrollment = useMemo(() => {
      switch (enrollment?.state) {
        case EnumEnrollmentState.draft:
          return t(".draft");
        case EnumEnrollmentState.pending:
          return t(".pending");
        case EnumEnrollmentState.published:
          return t(".published");
        case EnumEnrollmentState.active:
          return t(".active");
        case EnumEnrollmentState.closed:
          return t(".closed");
        default:
          return null;
      }
    });

    const displayPendingButton = useMemo(
      () =>
        enrollment?.state == EnumEnrollmentState.draft ||
        enrollment?.state == EnumEnrollmentState.pending,
      [enrollment]
    );

    const isPending = useMemo(
      () => enrollment?.state === EnumEnrollmentState.pending,
      [enrollment]
    );

    const handleChangeMaxState = useCallback(
      (state: EnumEnrollmentSubState) => {
        setMaxStep(STEP[state]);
      },
      []
    );

    const handlePendingEnrollment = useCallback(() => {
      Modal.open({
        title: t(".pendingButton"),
        children: (
          <>
            <Typography variant="body1" color={AppColor["Other/Danger"]}>
              {t(".pendingModalText1")}
            </Typography>
            <Box mt={4} mb={6}>
              <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                {t(".pendingModalText2")}
              </Typography>
            </Box>
          </>
        ),
        cancelButtonLabel: t(".cancel"),
        okButtonLabel: t(".pendingButton"),
        onOk: async ({ close }: any) => {
          await reEditMasterInsurance({ variables: { id } });

          close();
        },
        okButtonColor: AppColor["Other/Danger"],
      });
    }, [t, reEditMasterInsurance, id, refetch]);

    useEffect(() => {
      setStep(STEP[currentState]);
      refetch();
    }, [currentState, refetch]);

    useEffect(() => {
      const state = enrollment?.subState as keyof typeof STEP;
      setMaxStep(STEP[state]);
    }, [enrollment]);
    return {
      title,
      breadcrumbs,
      stepConfigs,
      step,
      loading,
      viewOnly,
      stateEnrollment,
      isPublished: enrollment?.state === EnumEnrollmentState.published,
      isPending,
      displayPendingButton,
      maxStep,
      handleChangeMaxState,
      handlePendingEnrollment,
    };
  })
);

export const EnrollmentDetailPage = enhancer(EnrollmentDetailComponent);
