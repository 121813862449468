import { GridColDef } from "@material-ui/data-grid";
import { Box, Field, Select } from "components";
import { EnumRowState } from "constants/enums/row-state";
import { compose, withHooks, withStores, withTranslation } from "enhancers";
import { TFunction } from "i18next";
import ViewInsuranceButton from "./ViewInsuranceButtonComponent";
import { gql } from "utils/helper";

interface InsuranceDetailProps {
  label: string;
  handleClickAddInsurance: () => void;
  t: TFunction;
  tableData: [];
  columns: GridColDef[];
  viewOnly: boolean;
  insuranceType: any;
  getInsuranceOptions: any;
  insurances: string[];
  isFamily: boolean;
}

const InsuranceSelectorComponent = (props: InsuranceDetailProps) => (
  <div>
    {props.insuranceType?.map((type: any, index: number) => (
      <Box display="flex" mb={6}>
        <Field
          component={Select}
          name={`insurances[${index}]`}
          label={type.name}
          options={props.getInsuranceOptions(type.id)}
          fullWidth
          // disabled={props.isFamily === null}
        />

        <Box alignSelf="center" ml={6}>
          <ViewInsuranceButton insurancePlanId={props.insurances?.[index]} />
        </Box>
      </Box>
    ))}
  </div>
);

const API = {
  FETCH_INSURANCE_DETAIL: gql`
    query FETCH_INSURANCE_DETAIL($id: String!) {
      masterInsuranceDetail(id: $id) {
        id
        year
        state
        subState
        companyName
        protectionStartDate
        protectionEndDate
        insurances {
          id
          nameTh
          nameEn
          premium
          remarkTh
          remarkEn
          description
          insuranceTypeId
          isFamily
          coverageLevel
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => {
    return {
      currentUser: stores.appStore.currentUser,
      insuranceSubState: stores.insuranceStore.insuranceSubState,
      packages: stores.insuranceStore.packages,
    };
  }),
  withTranslation({ prefix: "pages.main.insurance.InsurancePackage" }),
  withHooks((props: any, hooks: any) => {
    const {
      useCallback,
      useFormikContext,
      useMemo,
      useQuery,
      useParams,
      useDataTranslation,
    } = hooks;
    const { insuranceType, form } = props;
    const isFamily = form.values?.isFamily;
    const translatedInsuranceTypes = useDataTranslation(insuranceType);

    const formikBag = useFormikContext();
    const { insuranceId } = useParams();
    const { data } = useQuery(API.FETCH_INSURANCE_DETAIL, {
      variables: { id: insuranceId },
    });
    const { values } = formikBag;

    const insurancesTranslated = useDataTranslation(
      data?.masterInsuranceDetail.insurances
    );

    const insurances = useMemo(() => {
      if (insurancesTranslated) {
        let response: any = {};
        for (const insurance of insurancesTranslated) {
          response[insurance.id] = insurance;
        }
        return response;
      }
      return undefined;
    }, [insurancesTranslated]);

    const getInsuranceOptions = useCallback(
      (insuranceTypeId: string) => {
        if (insurances) {
          const list = Object.keys(insurances).map((key) => insurances[key]);

          const filteredList = list.map((insurance) => {
            if (
              insurance.rowState !== EnumRowState.DELETED &&
              insurance.insuranceTypeId === insuranceTypeId
              // isFamily !== null &&
              // insurance.isFamily === isFamily
            ) {
              return {
                label: insurance.name,
                value: insurance.id,
              };
            }
            return null;
          });

          return filteredList.filter((item) => item);
        }
        return [];
      },
      [insurances]
    );

    const formInsurances = useMemo(() => values?.insurances, [values]);

    return {
      insuranceType: translatedInsuranceTypes,
      getInsuranceOptions,
      insurances: formInsurances,
      isFamily,
    };
  })
);

export const InsuranceSelector = enhancer(InsuranceSelectorComponent);
