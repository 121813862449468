import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "components";
import { history } from "components/common/BrowserRouter";
import { EnumInsuranceFor } from "constants/enums/insurance-for";
import { PackagePricingType } from "constants/enums/package-pricing-type";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { isEmpty } from "lodash";
import { useCallback } from "react";
import insuranceStore from "stores/insuranceStore";
import { Yup, gql, homePath, notifySuccess, paths } from "utils/helper";
import { insuranceStepPath } from "../insuranceRoutes";
import { InsurancePackagesNewPage } from "./new";

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    backgroundColor: "#376FD0",
    borderColor: "#376FD0",
    color: "white",
    "&:hover": {
      backgroundColor: "#376FD0",
      borderColor: "#376FD0",
      color: "white",
    },
  },
  secondaryButton: {
    backgroundColor: "white",
    borderColor: "#376FD0",
    color: "#376FD0",
    "&:hover": {
      backgroundColor: "white",
      borderColor: "#376FD0",
      color: "#376FD0",
    },
  },
}));

export const API = {
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
  FETCH_INSURANCE_DETAIL: gql`
    query FETCH_INSURANCE_DETAIL($id: String!) {
      masterInsuranceDetail(id: $id) {
        id
        year
        state
        companyName
        protectionStartDate
        protectionEndDate
        insurances {
          id
          nameTh
          nameEn
          premium
          remarkTh
          remarkEn
          description
          insuranceTypeId
          isFamily
          coverageLevel
        }
        insurancePackages {
          id
          nameTh
          nameEn
          remarkTh
          remarkEn
          insurancesInsurancePackages {
            id
            insurancePlanId
            packageId
          }
          isFamily
          coverageLevel
          description
          premium
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    packages: stores.insuranceStore.packages,
  })),
  withFormik({
    mapPropsToValues: (props: any) => {
      const { insuranceId, duplicateId } = props.match.params;
      let initialValues = {
        nameTh: "",
        nameEn: "",
        packagePricingType: "",
        insurances: ["", "", ""],
        remarkTh: "",
        remarkEn: "",
        isFamily: null,
        coverageLevel: null,
        description: "",
        premium: null,
      };
      if (duplicateId) {
        const initFormStore = props.packages
          ? props.packages[duplicateId]
          : null;
        if (initFormStore) {
          initialValues = {
            ...initFormStore,
            nameTh: duplicateId
              ? `${initFormStore.nameTh}(Copy)`
              : initFormStore.nameTh,
          };
        } else {
          insuranceStepPath.insurancePackage(insuranceId).push();
        }
      }
      return initialValues;
    },
    validationSchema: Yup.object().shape({
      nameTh: Yup.string()
        .nullable()
        .test(
          "is-not-duplicate-name",
          ".insuranceNameDuplicate",
          (value, context) => {
            const packages = insuranceStore.state.packages;
            const editId = context?.parent?.editId;
            const filteredPackages = editId
              ? Object.entries(packages)
                  .filter(([key]) => key !== editId)
                  .map(([, value]) => value)
              : Object.values(packages);
            return !filteredPackages.some(
              (filteredPackage: any) => filteredPackage.nameTh === value
            );
          }
        ),
      packagePricingType: Yup.string()
        .required("ต้องไม่เว้นว่างเอาไว้")
        .nullable(true),
      coverageLevel: Yup.number().min(1, ".GteOne").typeError(".required"),
      insurances: Yup.array().of(
        Yup.string()
          .nullable()
          .test(
            "at-least-one-not-empty",
            ".notEmptyAtLeastOne",
            (value, context) => {
              const insurances = context?.parent;
              return insurances.some((insurance: any) => !isEmpty(insurance));
            }
          )
      ),
    }),
  }),
  withPreventLeaveDirtyForm({ onOk: () => history.goBack() }),
  withTranslation({ prefix: "pages.main.insurance.InsurancePackage" }),
  withHooks((props: any, hooks: any) => {
    const {
      useHandleSubmit,
      useEffect,
      useParams,
      useState,
      useQuery,
      useMemo,
    } = hooks;
    const { packages, t, values, setFieldValue, dirty } = props;
    const classes = useStyles();
    const { id, insuranceId } = useParams();
    const [title, setTitle] = useState();
    const [disabledPricingType, setDisabledPricingType] = useState(false);

    const { data: types, loading: insuranceTypeLoading } = useQuery(
      API.FETCH_INSURANCE_TYPES
    );

    const { data: detail, loading: detailLoading } = useQuery(
      API.FETCH_INSURANCE_DETAIL,
      {
        variables: { id: insuranceId },
        onCompleted: (data: any) => {
          const response = data.masterInsuranceDetail;
          if (!insuranceTypeLoading)
            insuranceStore.initialMasterInsurance(response);
        },
        fetchPolicy: "network-only",
      }
    );

    const packagePricingOptions = useMemo(() => {
      const options = Object.keys(PackagePricingType).map((key) => {
        const packagePricingType =
          PackagePricingType[key as keyof typeof PackagePricingType];

        const label = t(`.${packagePricingType}`);
        const value = key;
        return { label, value };
      });
      return options;
    }, [t]);

    const insuranceType = useMemo(() => types?.insuranceTypes || [], [types]);

    const onBack = useCallback(
      () => insuranceStepPath.insurancePackage(insuranceId).push(),
      [insuranceId]
    );

    useHandleSubmit(
      async (values: any) => {
        const clearEmptyInsurances = values.insurances.filter(
          (value: any) => !isEmpty(value)
        );

        if (clearEmptyInsurances.length > 0) {
          insuranceStore.saveInsurancePackageTemp(values);
          onBack();
          notifySuccess(t(".success"));
        } else {
          Modal.alert({
            title: "ไม่สามารถบันทึกได้",
            children: "กรุณาเลือกแบบประกันอย่างน้อย 1 ประเภท",
          });
        }
      },
      [packages, id]
    );

    useEffect(() => {
      setTitle(packages[id]?.nameTh || t(".add"));
    }, [id, packages, t]);

    const isLoading = useMemo(() => {
      return detailLoading || insuranceTypeLoading;
    }, [detailLoading, insuranceTypeLoading]);

    const config = useMemo(() => {
      if (isLoading) return;
      const year = detail?.masterInsuranceDetail.year;
      return {
        year,
      };
    }, [isLoading, detail]);

    const breadcrumbs = useMemo(() => {
      return [
        {
          path: homePath(),
          label: t(".breadcrumbs.home"),
        },
        {
          path: paths.insurancePath(),
          label: t(".breadcrumbs.insurance"),
        },
        {
          path: insuranceStepPath.insurancePackage(insuranceId).toString(),
          label: t(".breadcrumbs.insuranceDetail", {
            year: config?.year,
          }),
        },
        { path: null, label: title },
      ];
    }, [t, insuranceId, config, title]);

    const insuranceForOptions = useMemo(() => {
      return Object.keys(EnumInsuranceFor).map((key) => ({
        label: t(`.insuranceForOptions.${key}`),
        value: Boolean(EnumInsuranceFor[key as keyof typeof EnumInsuranceFor]),
      }));
    }, [t]);

    const isFamily = useMemo(() => values?.isFamily, [values?.isFamily]);

    const requiredPricingType = useMemo(
      () => values?.packagePricingType !== PackagePricingType.ALL_REMAINING,
      [values?.packagePricingType]
    );

    const setPremium = useCallback(() => {
      const insurances = values?.insurances;
      let sum = 0;
      insurances.forEach((id: string) => {
        const findInsurance = detail?.masterInsuranceDetail?.insurances.find(
          (insurance: any) => insurance.id === id
        );
        sum += Number(findInsurance?.premium || 0);
      });
      setFieldValue("premium", sum);
    }, [
      detail?.masterInsuranceDetail?.insurances,
      setFieldValue,
      values?.insurances,
    ]);

    useEffect(() => {
      if (values?.packagePricingType === PackagePricingType.SUM_INSURANCES) {
        setPremium();
      }
    }, [setPremium, values?.packagePricingType]);

    useEffect(() => {
      switch (values?.packagePricingType) {
        case PackagePricingType.SUM_INSURANCES:
          setDisabledPricingType(true);
          break;
        case PackagePricingType.ALL_REMAINING:
          setDisabledPricingType(true);
          if (dirty) {
            setFieldValue("premium", null);
          }
          break;
        case PackagePricingType.SET_OWN_PRICE:
          setDisabledPricingType(false);
          if (dirty) {
            setFieldValue("premium", null);
          }
          break;
        default:
          setDisabledPricingType(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFieldValue, values?.packagePricingType]);

    useEffect(() => {
      if (dirty) {
        setFieldValue("insurances", ["", "", ""]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFieldValue, values.isFamily]);

    return {
      classes,
      onBack,
      title,
      breadcrumbs,
      viewInsuranceState: "editing",
      packageIndex: id,
      packagePricingOptions,
      insuranceType,
      insuranceForOptions,
      isFamily,
      disabledPricingType,
      requiredPricingType,
    };
  })
);

export default enhancer(InsurancePackagesNewPage);
