import { gql } from "@apollo/client";
import {
  Box,
  Button,
  DatePicker,
  Field,
  Form,
  Grid,
  Modal,
  Notification,
  TextField,
  Typography,
} from "components";
import Authorize from "components/Authorize";
import Loader from "components/Loader";
import { EnumMasterInsuranceState } from "constants/enums/master-insurance-state";
import { EnumMasterInsuranceSubState } from "constants/enums/master-insurance-sub-state";
import { PERMISSIONS } from "constants/enums/permissions";
import { EnumRowState } from "constants/enums/row-state";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { TFunction } from "i18next";
import { isEmpty, isEqual, omitBy } from "lodash";
import { useHistory } from "react-router-dom";
import insuranceStore from "stores/insuranceStore";
import styled from "styled-components";
import { formatDate, paths, publishedAlert } from "utils/helper";
import { InsuranceList } from "./InsuranceList";
import { handleNavigateInsuranceStep } from "./insuranceRoutes";
import { MaterialIcon } from "components/common/MaterialIcon";
import { EnumInsuranceFor } from "constants/enums/insurance-for";
import { ReactComponent as UserIcon } from "assets/icon/user.svg";
import { ReactComponent as FamilyInsuranceIcon } from "assets/icon/family_insurance.svg";

const mapIcon = {
  employee: <UserIcon />,
  family: <FamilyInsuranceIcon />,
};

const InsuranceDataTitleContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 24px;
`;

interface InsuranceDetailProps {
  insuranceTypes: any[];
  t: TFunction;
  handleStartDateChange: (start: string) => void;
  handleEndDateChange: (end: string) => void;
  handleCompanyNameChange: (e: Object) => void;
  viewOnly: boolean;
  disablePreventLeaveDirtyForm: Function;
  minDateOfProtectionEndDate: string;
  isComponentLoading: boolean;
  hasSubmissionAction: boolean;
  handleClickAddInsurance: () => void;
}

const InsuranceDetailComponent = (props: InsuranceDetailProps) => {
  if (props.isComponentLoading) return <Loader />;
  return (
    <>
      <Typography variant="h4">{props.t(".duration")}</Typography>
      <Form style={{ width: "100%", marginTop: "16px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Field
              fast
              label={props.t(".protectionStartDate")}
              fullWidth
              component={DatePicker}
              name="protectionStartDate"
              placeholder=""
              onChange={props.handleStartDateChange}
              required
              disabled={props.viewOnly}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fast
              label={props.t(".protectionEndDate")}
              fullWidth
              component={DatePicker}
              name="protectionEndDate"
              placeholder=""
              onChange={props.handleEndDateChange}
              required
              disabled={props.viewOnly}
            />
          </Grid>
        </Grid>
        <InsuranceDataTitleContainer>
          <Typography variant="h4">{props.t(".insuranceData")}</Typography>
          {!props.viewOnly && (
            <>
              <Button
                onClick={props.handleClickAddInsurance}
                variant="outlined"
              >
                <MaterialIcon name="Add" style={{ marginRight: "16px" }} />
                {props.t(".add")}
              </Button>
            </>
          )}
        </InsuranceDataTitleContainer>

        <Box display="flex" alignItems="center" mt={"24px"} mb="16px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="24px"
            height="24px"
          >
            <UserIcon />
          </Box>
          <Typography variant="body2" ml="8px">
            {props.t(`.insuranceFor.employee`)}
          </Typography>
        </Box>
        {props.insuranceTypes.map((type) => (
          <InsuranceList
            key={type.id}
            label={type.name}
            id={type.id}
            symbol={type.symbol}
            viewOnly={props.viewOnly}
            disablePreventLeaveDirtyForm={props.disablePreventLeaveDirtyForm}
            // isFamily={Boolean(
            //   EnumInsuranceFor[key as keyof typeof EnumInsuranceFor]
            // )}
          />
        ))}

        <Authorize permissions={[PERMISSIONS.INSURANCE_MANAGEMENT_EDIT]}>
          {!props.viewOnly && (
            <Button type="submit" mt="16px">
              {props.hasSubmissionAction
                ? props.t(".saveAndNext")
                : props.t(".save")}
            </Button>
          )}
        </Authorize>
      </Form>
    </>
  );
};

const API = {
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
  FETCH_INSURANCE_DETAIL: gql`
    query FETCH_INSURANCE_DETAIL($id: String!) {
      masterInsuranceDetail(id: $id) {
        id
        year
        state
        subState
        companyName
        protectionStartDate
        protectionEndDate
        insurances {
          id
          nameTh
          nameEn
          premium
          remarkTh
          remarkEn
          description
          insuranceTypeId
          isFamily
          coverageLevel
        }
        insurancePackages {
          id
          nameTh
          nameEn
          remarkTh
          remarkEn
          insurancesInsurancePackages {
            id
            insurancePlanId
            packageId
          }
          isFamily
          coverageLevel
          description
          premium
        }
      }
    }
  `,
  UPDATE_MASTER_INSURANCE: gql`
    mutation UPDATE_MASTER_INSURANCE(
      $id: String!
      $state: String!
      $subState: String!
      $protectionStartDate: String
      $protectionEndDate: String
      $insurances: [JSON!]
    ) {
      updateMasterInsurance(
        input: {
          id: $id
          state: $state
          subState: $subState
          protectionStartDate: $protectionStartDate
          protectionEndDate: $protectionEndDate
          insurances: $insurances
        }
      ) {
        id
        year
        state
        subState
        protectionStartDate
        protectionEndDate
        insurances {
          id
          nameTh
          nameEn
          premium
          remarkTh
          remarkEn
          description
          insuranceTypeId
        }
        insurancePackages {
          id
          nameTh
          nameEn
          remarkTh
          remarkEn
          insurancesInsurancePackages {
            id
            insurancePlanId
            packageId
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => {
    return {
      currentUser: stores.appStore.currentUser,
      insurances: stores.insuranceStore.insurances,
      initInsurances: stores.insuranceStore.initInsurances,
      companyName: stores.insuranceStore.companyName,
      protectionStartDate: stores.insuranceStore.protectionStartDate,
      protectionEndDate: stores.insuranceStore.protectionEndDate,
      insuranceSubState: stores.insuranceStore.insuranceSubState,
      initMasterInsuranceDetail:
        stores.insuranceStore.initMasterInsuranceDetail,
    };
  }),
  withFormik({
    mapPropsToValues: (props: any) => {
      return {
        ...props.initMasterInsuranceDetail,
        insurancesChange: false,
      };
    },
  }),
  withPreventLeaveDirtyForm({ onOk: () => insuranceStore.clearData() }),
  withTranslation({ prefix: "pages.main.insurance.InsuranceDetail" }),
  withHooks((props: any, hooks: any) => {
    const {
      useMemo,
      useCallback,
      useQuery,
      useParams,
      useMutation,
      useEffect,
      useHandleSubmit,
      useDataTranslation,
    } = hooks;
    const {
      insurances,
      initInsurances,
      protectionStartDate,
      protectionEndDate,
      companyName,
      setInitialValues,
      initialValues,
      setFieldValue,
      disablePreventLeaveDirtyForm,
      t,
    } = props;

    const { id } = useParams();

    const { data: types, loading } = useQuery(API.FETCH_INSURANCE_TYPES);
    const { data: detail, loading: detailLoading } = useQuery(
      API.FETCH_INSURANCE_DETAIL,
      {
        variables: { id },
        onCompleted: async (data: any) => {
          const response = data.masterInsuranceDetail;
          insuranceStore.initialMasterInsurance(response);
          await setInitialValues({
            companyName: response.companyName,
            protectionStartDate: response.protectionStartDate,
            protectionEndDate: response.protectionEndDate,
            insurancesChange: false,
          });
          if (protectionStartDate)
            setFieldValue("protectionStartDate", protectionStartDate);
          if (protectionEndDate)
            setFieldValue("protectionEndDate", protectionEndDate);
          if (companyName) setFieldValue("companyName", companyName);
        },
        fetchPolicy: "network-only",
      }
    );

    const hasSubmissionAction = useMemo(() => {
      return (
        detail?.masterInsuranceDetail.subState ===
        EnumMasterInsuranceSubState.insurance_creating
      );
    }, [detail]);

    const [updateMasterInsurance] = useMutation(API.UPDATE_MASTER_INSURANCE, {
      onCompleted: (data: any) => {
        const response = data.updateMasterInsurance;
        Notification.success(t(".saveSuccess"));
        if (hasSubmissionAction) {
          insuranceStore.clearData();
          handleNavigateInsuranceStep(response.id, response.subState);
        }
      },
      skipSetError: true,
      onError: () => {
        publishedAlert(disablePreventLeaveDirtyForm);
      },
    });

    const insuranceTypes = useDataTranslation(types?.insuranceTypes);

    const checkInsurancePlanIsDirty = useCallback(
      (initInsurances: any, insurances: any) => {
        if (!isEmpty(insurances)) {
          let isDirty = false;
          const insurancesArray = Object.keys(insurances).map((key) => {
            return insurances[key];
          });
          const modified = insurancesArray.filter(
            (insurance) => insurance.rowState === EnumRowState.MODIFIED
          );

          const prevIds = initInsurances.map((id: any) => id);
          const currentIds = Object.keys(insurances).filter(
            (key) => insurances[key].rowState !== EnumRowState.DELETED
          );

          if (modified.length > 0) isDirty = true;
          if (!isEqual(JSON.stringify(prevIds), JSON.stringify(currentIds)))
            isDirty = true;

          if (isDirty) {
            setFieldValue("insurancesChange", true);
          } else {
            setFieldValue("insurancesChange", false);
          }
        } else {
          console.error("insurances object is not defined");
        }
      },
      [setFieldValue]
    );

    useHandleSubmit(
      async (values: any) => {
        const currentInsurances = Object.keys(insurances).map(
          (key) => insurances[key]
        );
        const withoutDelete = currentInsurances.filter(
          (data) => data.rowState !== EnumRowState.DELETED
        );
        const deletingItems = currentInsurances.filter(
          (data) =>
            data.rowState === EnumRowState.DELETED &&
            initInsurances.includes(data.id)
        );
        const parameters = {
          id,
          state: EnumMasterInsuranceState.draft,
          subState: EnumMasterInsuranceSubState.insurance_package_creating,
          protectionStartDate: formatDate(
            values.protectionStartDate,
            "yyyy-MM-dd"
          ),
          protectionEndDate: formatDate(values.protectionEndDate, "yyyy-MM-dd"),
          insurances: [...withoutDelete, ...deletingItems],
        };
        await updateMasterInsurance({
          variables: { ...parameters },
        });
      },
      [id, insurances, updateMasterInsurance, initInsurances]
    );

    const handleStartDateChange = useCallback(
      (start: string) => {
        insuranceStore.setState({ protectionStartDate: start });
        setFieldValue("protectionStartDate", start);
      },
      [setFieldValue]
    );

    const handleEndDateChange = useCallback(
      (end: string) => {
        insuranceStore.setState({ protectionEndDate: end });
        setFieldValue("protectionEndDate", end);
      },
      [setFieldValue]
    );

    const handleCompanyNameChange = useCallback(
      (e: any) => {
        insuranceStore.setState({ companyName: e.target?.value });
        setFieldValue("companyName", e.target?.value);
      },
      [setFieldValue]
    );

    const handleClickAddInsurance = useCallback(async () => {
      await disablePreventLeaveDirtyForm();
      paths.addInsurancePath(id, { insuranceType: "symbol" }).push();
    }, [id, disablePreventLeaveDirtyForm]);

    const isComponentLoading = useMemo(() => loading || detailLoading, [
      loading,
      detailLoading,
    ]);

    const viewOnly = useMemo(
      () =>
        detail?.masterInsuranceDetail.state ===
        EnumMasterInsuranceState.published,
      [detail]
    );

    useEffect(() => {
      if (!isEmpty(initialValues))
        checkInsurancePlanIsDirty(initInsurances, insurances);
    }, [checkInsurancePlanIsDirty, initInsurances, insurances, initialValues]);

    const history = useHistory();

    useEffect(() => {
      const unlisten = history.listen((location, action) => {
        // Perform actions when navigating to a new route
        console.log("Navigating to:", location.pathname);
        insuranceStore.clearData();
      });

      // Clean up the listener when the component unmounts
      return () => {
        unlisten();
      };
    }, [history]);

    return {
      insuranceTypes,
      handleStartDateChange,
      handleEndDateChange,
      handleCompanyNameChange,
      disablePreventLeaveDirtyForm,
      isComponentLoading,
      viewOnly,
      hasSubmissionAction,
      handleClickAddInsurance,
    };
  })
);

export const InsuranceDetail = enhancer(InsuranceDetailComponent);
