import { Box, Button, Grid, Typography } from "components";
import { history } from "components/common/BrowserRouter";
import { EnumInsuranceFor } from "constants/enums/insurance-for";
import { compose, withHooks, withStores, withTranslation } from "enhancers";
import { TFunction } from "i18next";
import { PageContent } from "layouts";
import { BreadcrumbsProps } from "layouts/PageContent";
import { isEmpty } from "lodash";
import styled from "styled-components";
import { formatDate, gql, homePath, paths, toCurrency } from "utils/helper";

const CustomHtmlContainer = styled(Box)`
  .CustomHtml > div > p {
    margin: 0;
  }
`;

interface EnrollmentInsuranceProps {
  t: TFunction;
  title: string;
  breadcrumbs: BreadcrumbsProps[];
  displayData: { label: string; value: string; type?: string }[];
  handleClickBack: () => void;
}

const EnrollmentInsuranceComponent = (props: EnrollmentInsuranceProps) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs} noPadding>
    <Box p="16px 16px 24px 16px">
      {props.displayData.map((data) => (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="body2" style={{ lineHeight: "28px" }}>
              {data.label}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {data.type === "html" ? (
              <CustomHtmlContainer>
                <Typography
                  className="CustomHtml"
                  variant="body1"
                  style={{ lineHeight: "28px" }}
                >
                  {data.value ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.value,
                      }}
                    />
                  ) : (
                    "-"
                  )}
                </Typography>
              </CustomHtmlContainer>
            ) : (
              <Typography variant="body1" style={{ lineHeight: "28px" }}>
                {data.value}
              </Typography>
            )}
          </Grid>
        </Grid>
      ))}
      <Button
        style={{ marginTop: "40px" }}
        variant="outlined"
        onClick={props.handleClickBack}
      >
        {props.t(".back")}
      </Button>
    </Box>
  </PageContent>
);

const API = {
  GET_ENROLLMENT: gql`
    query GET_ENROLLMENT($id: String!) {
      enrollment(id: $id) {
        id
        year
      }
    }
  `,
  GET_INSURANCE_PLAN: gql`
    query GET_INSURANCE_PLAN($id: String!) {
      insurancePlan(id: $id) {
        nameTh
        nameEn
        premium
        remarkTh
        remarkEn
        description
        protectionStartDate
        protectionEndDate
        description
        insuranceType {
          nameTh
          nameEn
        }
        isFamily
        coverageLevel
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({ prefix: "pages.main.enrollment.enrollmentInsurance" }),
  withHooks((props: any, hooks: any) => {
    const {
      useMemo,
      useCallback,
      useParams,
      useQuery,
      useMutation,
      useDataTranslation,
    } = hooks;
    const { t } = props;
    const { id, insuranceId } = useParams();
    const { data: enrollmentRes } = useQuery(API.GET_ENROLLMENT, {
      variables: { id },
    });
    const { data: insurancePlanRes } = useQuery(API.GET_INSURANCE_PLAN, {
      variables: { id: insuranceId },
      fetchPolicy: "network-only",
    });

    const insuranceTranslated = useDataTranslation(insurancePlanRes);

    const enrollment = useMemo(() => enrollmentRes?.enrollment, [
      enrollmentRes,
    ]);

    const insurancePlan = useMemo(() => insuranceTranslated?.insurancePlan, [
      insuranceTranslated,
    ]);
    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        {
          path: homePath(),
          label: t(".home"),
        },
        {
          path: paths.enrollmentPath(),
          label: t(".enrollment"),
        },
        {
          path: paths.enrollmentDetailPath(id),
          label: `${t(".enrollment")} ${enrollment?.year}`,
        },
        {
          path: null,
          label: insurancePlan?.name,
        },
      ],
      [t, id, enrollment, insurancePlan]
    );

    const displayData = useMemo(() => {
      if (insurancePlan) {
        const isFamilyText = Object.entries(EnumInsuranceFor).find(
          ([key, value]) => value === insurancePlan.isFamily
        )?.[0];
        return [
          {
            label: t(".name"),
            value: insurancePlan.name || "-",
          },
          // {
          //   label: t(".isFamily"),
          //   value: isEmpty(isFamilyText)
          //     ? "-"
          //     : t(`.insuranceFor.${isFamilyText}`),
          // },
          {
            label: t(".insuranceType"),
            value: insurancePlan.insuranceType.name || "-",
          },
          // {
          //   label: t(".coverageLevel"),
          //   value: insurancePlan.coverageLevel || "-",
          // },
          {
            label: t(".protectionStartDate"),
            value:
              formatDate(insurancePlan.protectionStartDate, "dd/MM/yyyy") ||
              "-",
          },
          {
            label: t(".protectionEndDate"),
            value:
              formatDate(insurancePlan.protectionEndDate, "dd/MM/yyyy") || "-",
          },
          {
            label: t(".premium"),
            value:
              toCurrency(insurancePlan.premium, {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }) || "-",
          },
          {
            label: t(".remark"),
            value: insurancePlan.remark || "-",
          },
          {
            label: t(".description"),
            value: insurancePlan.description || "-",
            type: "html",
          },
        ];
      }
      return [];
    }, [t, insurancePlan]);

    const handleClickBack = useCallback(() => {
      history.goBack();
    }, []);

    return {
      title: insurancePlan?.name,
      breadcrumbs,
      displayData,
      handleClickBack,
    };
  })
);

export const EnrollmentInsurancePage = enhancer(EnrollmentInsuranceComponent);
