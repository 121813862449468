import { gql } from "@apollo/client";
import { BreadcrumbProps } from "antd";
import {
  Box,
  Button,
  CKEditor,
  Field,
  Form,
  Grid,
  Select,
  TextField,
  Typography,
} from "components";
import { EnumMasterInsuranceState } from "constants/enums/master-insurance-state";
import { PERMISSIONS } from "constants/enums/permissions";
import { IInsuranceDetail } from "constants/interfaces/insuranceDetail";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";

import { TFunction } from "i18next";
import { PageContent } from "layouts";
import paths from "routes/paths";
import insuranceStore from "stores/insuranceStore";
import { AppColor } from "theme/app-color";
import { Yup, homePath, notifySuccess } from "utils/helper";
import { v4 as uuidv4 } from "uuid";
import { insuranceStepPath } from "./insuranceRoutes";
import { EnumInsuranceFor } from "constants/enums/insurance-for";
import { isEmpty } from "lodash";

interface InsuranceFormProps {
  t: TFunction;
  title: string;
  breadcrumbs: BreadcrumbProps;
  values: any;
  handleClickCancel: () => void;
  viewOnly: boolean;
  insuranceTypeOptions: any[];
  insuranceForOptions: any[];
  insuranceId: string;
}

const InsuranceFormComponent = (props: InsuranceFormProps) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <Typography variant="h4" color={AppColor["Text/Black"]} mb={6}>
      {props.t(".info")}
    </Typography>
    <Form style={{ width: "100%" }}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Field
            type="text"
            label={props.t(".nameTh")}
            fullWidth
            component={TextField}
            name="nameTh"
            placeholder=""
            required
            disabled={props.viewOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            type="text"
            label={props.t(".nameEn")}
            fullWidth
            component={TextField}
            name="nameEn"
            placeholder=""
            required
            disabled={props.viewOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={Select}
            name="insuranceTypeId"
            type="text"
            label={props.t(".insuranceType")}
            options={props.insuranceTypeOptions}
            fast
            required
            fullWidth
            disableClearable={true}
            disabled={props.viewOnly || !isEmpty(props.insuranceId)}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <Field
            component={Select}
            name="isFamily"
            type="text"
            label={props.t(".insuranceFor")}
            options={props.insuranceForOptions}
            fast
            required
            fullWidth
            disableClearable={true}
            disabled={props.viewOnly || !isEmpty(props.insuranceId)}
          />
        </Grid> */}
        <Grid item xs={6}>
          <Field
            label={props.t(".premium")}
            fullWidth
            component={TextField}
            name="premium"
            placeholder=""
            required
            type="number"
            thousandSeparator
            disabled={props.viewOnly}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <Field
            type="number"
            label={props.t(".coverageLevel")}
            fullWidth
            component={TextField}
            name="coverageLevel"
            placeholder=""
            disabled={props.viewOnly}
            required
          />
          <Box mt="6px">
            <Typography variant="caption" color={AppColor["Text/Line"]}>
              {props.t(".coverageLevelHelper")}
            </Typography>
          </Box>
        </Grid> */}
        <Grid item xs={12}>
          <Field
            type="text"
            label={props.t(".remarkTh")}
            fullWidth
            component={TextField}
            name="remarkTh"
            placeholder=""
            disabled={props.viewOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            type="text"
            label={props.t(".remarkEn")}
            fullWidth
            component={TextField}
            name="remarkEn"
            placeholder=""
            disabled={props.viewOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            component={CKEditor}
            name="description"
            placeholder=""
            disabled={props.viewOnly}
          />
        </Grid>
        <Grid item xs={4} style={{ marginTop: "24px" }}>
          <Button
            onClick={props.handleClickCancel}
            style={{ marginRight: "16px" }}
            variant="outlined"
          >
            {props.t(".cancel")}
          </Button>
          {!props.viewOnly && <Button type="submit">{props.t(".save")}</Button>}
        </Grid>
      </Grid>
    </Form>
  </PageContent>
);

const API = {
  FETCH_INSURANCE_DETAIL: gql`
    query FETCH_INSURANCE_DETAIL($id: String!) {
      masterInsuranceDetail(id: $id) {
        id
        year
      }
    }
  `,
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => {
    return {
      currentUser: stores.appStore.currentUser,

      insurances: stores.insuranceStore.insurances,
      insuranceSubState: stores.insuranceStore.insuranceSubState,
    };
  }),
  withFormik({
    mapPropsToValues: (props: any) => {
      const { id, insuranceId, duplicateId } = props.match.params;
      let initialValues = {
        nameTh: null,
        nameEn: "",
        remarkTh: null,
        remarkEn: "",
        premium: null,
        description: "",
        insuranceTypeId: null,
        isFamily: null,
        coverageLevel: null,
      };
      if (!props.insurances) {
        insuranceStepPath.insuranceDetail(id).push();
      }
      if (insuranceId || duplicateId) {
        const initFormStore = props.insurances
          ? props.insurances[insuranceId || duplicateId]
          : null;
        if (initFormStore) {
          initialValues = {
            ...initFormStore,
            nameTh: duplicateId
              ? `${initFormStore.nameTh}(Copy)`
              : initFormStore.nameTh,
            editId: insuranceId,
          };
        } else {
          insuranceStepPath.insuranceDetail(id).push();
        }
      }
      return initialValues;
    },
    validationSchema: Yup.object().shape({
      nameTh: Yup.string()
        .nullable()
        .test(
          "is-not-duplicate-name",
          ".insuranceNameThDuplicate",
          (value, context) => {
            const insurances = insuranceStore.state.insurances;
            const editId = context?.parent?.editId;
            const filteredInsurances = editId
              ? Object.values(insurances || {}).filter(
                  (item: any) => item.id !== editId
                )
              : Object.values(insurances || {});
            return !filteredInsurances.some(
              (filteredInsurance: any) => filteredInsurance.nameTh === value
            );
          }
        ),
      nameEn: Yup.string()
        .nullable()
        .test(
          "is-not-duplicate-name-en",
          ".insuranceNameEnDuplicate",
          (value, context) => {
            const insurances = insuranceStore.state.insurances;
            const editId = context?.parent?.editId;
            const filteredInsurances = editId
              ? Object.values(insurances || {}).filter(
                  (item: any) => item.id !== editId
                )
              : Object.values(insurances || {});
            return !filteredInsurances.some(
              (filteredInsurance: any) => filteredInsurance.nameEn === value
            );
          }
        ),
      premium: Yup.number().min(0, ".GteZero").typeError(".required"),
      // coverageLevel: Yup.number().min(1, ".GteOne").typeError(".required"),
    }),
  }),
  withPreventLeaveDirtyForm(),
  withTranslation({ prefix: "pages.main.insurance.InsuranceForm" }),
  withHooks((props: any, hooks: any) => {
    const {
      useMemo,
      useCallback,
      useParams,
      useHandleSubmit,
      useQuery,
      useLocation,
      useEffect,
      useDataTranslation,
    } = hooks;

    const {
      values,
      initialValues,
      insurances,
      setInitialValues,
      insuranceSubState,
      currentUser,
      t,
    } = props;

    const hasEditPermission = useMemo(
      () =>
        currentUser?.role?.permissions.includes(
          PERMISSIONS.INSURANCE_MANAGEMENT_EDIT
        ),
      [currentUser]
    );

    const viewOnly = useMemo(() => {
      if (insuranceSubState === EnumMasterInsuranceState.published) return true;
      if (!hasEditPermission) return true;
      return false;
    }, [insuranceSubState, hasEditPermission]);

    const { id: detailId, insuranceId } = useParams();
    const { search } = useLocation();
    const insuranceType = useMemo(
      () => new URLSearchParams(search).get("insuranceType"),
      [search]
    );

    const { data: detail, loading: detailLoading } = useQuery(
      API.FETCH_INSURANCE_DETAIL,
      {
        variables: { id: detailId },
      }
    );
    const { data: types, loading: insuranceTypeLoading } = useQuery(
      API.FETCH_INSURANCE_TYPES
    );

    const isLoading = useMemo(() => {
      return detailLoading || insuranceTypeLoading;
    }, [detailLoading, insuranceTypeLoading]);

    const config = useMemo(() => {
      if (isLoading) return;
      const year = detail?.masterInsuranceDetail.year;
      return {
        year,
      };
    }, [isLoading, detail]);

    const typesTranslated = useDataTranslation(types);
    const insurancesTranslated = useDataTranslation(insurances);

    const insuranceTypes = useMemo(
      () => typesTranslated?.insuranceTypes || [],
      [typesTranslated]
    );

    const insuranceTypeDetail = useMemo(() => {
      if (insuranceType) {
        const found = insuranceTypes.find(
          (type: any) => type.symbol === insuranceType
        );
        return found;
      } else if (insuranceId && insurancesTranslated) {
        const insurance = insurancesTranslated[insuranceId];

        const found = insuranceTypes.find(
          (type: any) => type.id === insurance.insuranceTypeId
        );
        return found;
      }
    }, [insuranceTypes, insuranceType, insurancesTranslated, insuranceId]);

    const title = useMemo(() => {
      if (insuranceType) return insuranceTypeDetail?.name;
      if (insuranceId && insurancesTranslated)
        return insurancesTranslated[insuranceId].name;
    }, [insuranceType, insuranceTypeDetail, insuranceId, insurancesTranslated]);

    const insuranceTypeOptions = useMemo(() => {
      return insuranceTypes.map((insuranceType: any) => ({
        label: insuranceType?.name,
        value: insuranceType?.id,
      }));
    }, [insuranceTypes]);

    const insuranceForOptions = useMemo(() => {
      return Object.keys(EnumInsuranceFor).map((key) => ({
        label: t(`.insuranceForOptions.${key}`),
        value: Boolean(EnumInsuranceFor[key as keyof typeof EnumInsuranceFor]),
      }));
    }, [t]);

    const breadcrumbs = useMemo(() => {
      return [
        {
          path: homePath(),
          label: t(".breadcrumbs.home"),
        },
        {
          path: paths.insurancePath(),
          label: t(".breadcrumbs.insurance"),
        },
        {
          path: paths.insuranceDetailPath(detailId),
          label: t(".breadcrumbs.insuranceDetail", {
            year: config?.year,
          }),
        },
        { path: null, label: title || t(".addInsurance") },
      ];
    }, [t, detailId, config?.year, title]);

    useHandleSubmit((values: any) => {
      const id = insuranceId ? insuranceId : uuidv4();
      insuranceStore.saveInsuranceTemp(id, {
        ...values,
        isFamily: false,
        coverageLevel: 1,
        id,
      });
      insuranceStepPath.insuranceDetail(detailId).push();
      notifySuccess(t(".success"));
    }, []);

    const handleClickCancel = useCallback(() => {
      insuranceStepPath.insuranceDetail(detailId).push();
    }, [detailId]);

    return {
      title: title || t(".addInsurance"),
      breadcrumbs,
      values,
      handleClickCancel,
      viewOnly,
      insuranceTypeOptions,
      insuranceForOptions,
      insuranceId,
    };
  })
);

export const InsuranceFormPage = enhancer(InsuranceFormComponent);
